<template>
	<div>
		<div class="d-flex">
			<span class="display-1 mb-3">
				{{ user.name.full }}
			</span>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'UserInfo',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loading: false
		}
	},
	computed: {
		...mapGetters({
			user: 'users/user'
		})
	}
}
</script>
